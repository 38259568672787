<template>
  <!-- 新增渠道商 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />

    <div class="main-content">
      <div class="body">
        <div class="form">
          <a-steps :current="stepsCurrent">
            <a-step
              v-for="item in steps"
              :key="item.title"
              :title="item.title"
              :description="item.description"
            />
          </a-steps>

          <!-- 基础信息-start -->
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 17 }"
            v-show="stepsCurrent === 0"
          >
            <a-form-model-item ref="name" label="门店名称" prop="name">
              <a-input
                v-model="form.name"
                placeholder="请输入门店名称"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              ref="username"
              label="使用者姓名"
              prop="username"
            >
              <a-input
                v-model="form.username"
                placeholder="请输入使用者姓名"
                @blur="
                  () => {
                    $refs.username.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              ref="businessAddress_id"
              label="所绑定的线路"
              prop="businessAddress_id"
            >
              <a-select
                class="select"
                :default-value="0"
                placeholder="请选择绑定路线"
                v-model="form.businessAddress_id"
                @change="handleSelectChange"
              >
                <a-select-option
                  v-for="(item, index) in PointsList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="选择省市区" prop="areaId">
              <a-cascader
                :options="cascaderAreaOptions"
                placeholder="请选择省市区"
                expand-trigger="hover"
                :field-names="fieldNames"
                v-model="form.areaId"
              />
            </a-form-model-item>
            <a-form-model-item ref="address" label="详细地址" prop="address">
              <a-select
                allow-clear
                show-search
                placeholder="请输入详细地址（街道、门牌号）"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                @search="handleAddressChange"
                @select="handleAddressSelect"
                :not-found-content="null"
                v-model="form.address"
              >
                <a-select-option
                  v-for="(d, index) in addressReturn"
                  :key="d.value + index"
                >
                  {{ d.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="分布点经/纬度" prop="longiLatitue">
              <a-input
                v-model="form.longiLatitue"
                placeholder="请输入分布点经/纬度"
                disabled
              />
            </a-form-model-item>
            <a-form-model-item ref="mobile" label="手机号码" prop="mobile">
              <a-input
                v-model="form.mobile"
                placeholder="请输入手机号码"
                @blur="
                  () => {
                    $refs.mobile.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                class="submit-btn"
                type="primary"
                :loading="addLoading"
                @click="onAddSubmit"
              >
                下一步
              </a-button>
            </a-form-model-item>
          </a-form-model>
          <!-- 基础信息-end -->
          <!-- 绑定微信号-start -->
          <a-form-model
            :label-col="{ span: 9 }"
            :wrapper-col="{ span: 6 }"
            v-show="stepsCurrent === 1"
          >
            <div class="bind-status" v-if="qrcodeScanStatu">
              <div class="icon-box">
                <a-icon
                  theme="filled"
                  :style="{ color: '#FF4D4F', fontSize: '70px' }"
                  type="info-circle"
                />
              </div>
              <div class="bind-title">已绑定微信，账号待开通</div>
              <div class="bind-p">请在联系公司后台负责人开通账号</div>
              <div class="btn-box">
                <a-button class="bind-btn" @click="$router.go(-2)">
                  返回
                </a-button>
              </div>
            </div>
            <template v-else>
              <div class="qrcode-box">
                <div class="qrcode">
                  <iframe
                    id="iframe"
                    :src="wechatLink"
                    width="300px"
                    height="400px"
                    frameborder="0"
                    name="iframea"
                    sandbox="allow-scripts allow-top-navigation allow-same-origin"
                  ></iframe>
                </div>
              </div>
            </template>
          </a-form-model>
          <!-- 绑定微信号-end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NP from 'number-precision'
import { Debounce } from '../../utils/public'
export default {
  data() {
    return {
      title: '',
      stepsCurrent: 0,
      steps: [
        {
          title: '基础信息',
          description: '基础信息'
        },
        {
          title: '绑定微信号',
          description: '用于中台的账号登录'
        }
      ],
      form: {
        name: '',
        username: '',
        mobile: '',
        address: undefined,
        areaId: undefined,
        longiLatitue: '',
        businessAddress_id: undefined
      },
      rules: {
        name: [{ required: true, message: '请输入门店名称', trigger: 'blur' }],
        username: [
          { required: true, message: '请输入使用人名称', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        businessAddress_id: [
          { required: true, message: '请选择绑定线路', trigger: 'change' }
        ],
        areaId: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },
      cascaderAreaOptions: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      addressReturn: [],
      PointsList: [],
      addLoading: false,
      wechatLink: '',
      qrcodeScanStatu: false
    }
  },
  created() {
    this.title = "新增门店"
    const query = this.$route.query
    if (query.code) {
      // 绑定微信
      this.stepsCurrent = 1
      const params = {
        code: query.code,
        userType: query.userType,
        id: query.id
      }
      this.channelBusinessBindWX(params)
    } else if (query.status) {
      // 绑定成功
      this.stepsCurrent = 1
      this.qrcodeScanStatu = true
    } else {
      //  this.form.bcid = query.bcid
    }
  },
  mounted() {
    this.getPointsList()
    this.getArea()
  },
  methods: {
    async getPointsList() {
      const points = await this.$axios.getPointsList()
      this.PointsList = points.data.data
      // 
    },
    async getArea(){
      const area = await this.$axios.getArea()
      this.cascaderAreaOptions = area.nested
      console.log('PointsList', this.cascaderAreaOptions)
    },
    onAddSubmit() {
      // 新增门店
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const hide = this.$message.loading('请稍后', 0)
          const data = JSON.parse(JSON.stringify(this.form))
          const areaId = data.areaId
          data.areaId = areaId[areaId.length - 1]
          this.addLoading = true
          this.$axios.getStoreAdd(data).then((res) => {
            let subData = {
              userType: 'store',
              id: res.data.data.id
            }

            this.$axios.getNeedStore(subData).then((subRes) => {
              this.wechatLink = subRes.data.data.url
              this.stepsCurrent++
              this.addLoading = false
              hide()
            })
            console.log(subData)
          })
          //       const hide = this.$message.loading('请稍后', 0)
          //       const data = {
          //         realName: this.form.realName,
          //         rate: NP.divide(this.form.rate, 100)
          //       }

          //       this.$axios.getStoreAdd(data).then((res) => {
          //   const subData = {
          //     userType: 'business',
          //     id: res.data.data.id,
          //     key: 'channelBusinessAdd'
          //   }
          //         // 获取绑定微信二维码
          //   this.$axios.getChannelBindWXLink(subData).then((subRes) => {
          //     this.wechatLink = subRes.data.data.url
          //     this.stepsCurrent++
          //     this.addLoading = false
          //     hide()
          //   })
          //       })
        }
      })
    },
    channelBusinessBindWX(data) {
      // 绑定微信
      const hide = this.$message.loading('请稍后', 0)
      this.$axios.getbindStoreOpenid(data).then(() => {
        hide()
        this.qrcodeScanStatu = true
        this.$router.replace({
          query: {
            status: 1
          }
        })
      })
    },
    handleAddressChange: Debounce(function (e) {
      // 地址搜索
      if (!e) return
      const baseUrl = `https://apis.map.qq.com/ws/place/v1/search?keyword=${e}&key=${process.env.VUE_APP_QQMAPKEY}&output=jsonp&boundary=region(中国)`
      this.$jsonp(baseUrl)
        .then((res) => {
          console.log("11111",res)
          const data = res.data.map((v) => {
            if (v.title) {
              return {
                value: `${v.title}`,
                longiLatitue: `${v.location.lng},${v.location.lat}`
              }
            }
          })
          this.addressReturn = data
        })
        .catch((err) => {
          console.log(err)
        })
    }, 500),
    handleAddressSelect(key) {
      // 监听地址选择
      const index = key.substring(key.length - 1)
      const item = this.addressReturn[index]
      this.form.longiLatitue = item.longiLatitue
    },
    handleSelectChange(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #fff;
  padding: 48px 0;
}

.form {
  width: 450px;
  margin: 0 auto;
}

.ant-form {
  margin-top: 40px;
}

.submit-btn {
  margin-left: 9.5em;
}

.qrcode-box {
  height: 400px;
  position: relative;
}

.qrcode {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.bind-status {
  width: 464px;
  margin: 40px auto 0 auto;
}

.bind-status .icon-box {
  width: 70px;
  height: 70px;
  margin: 30px auto;
}

.bind-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}

.bind-p {
  text-align: center;
  font-size: 14px;
  height: 22px;
  margin: 8px auto 24px auto;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
